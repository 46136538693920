<template>
  <div class="home">
    <header class="home__header">
      <a class="home__logo" href="https://poptions.io/">
        <svg class="home__logo-svg" viewBox="0 0 1468.51 327.13">
          <defs>
            <linearGradient id="b" x1="6.93" y1="319.72" x2="319.72" y2="6.93" gradientUnits="userSpaceOnUse">
              <stop offset="0" stop-color="#843b96"/><stop offset="1" stop-color="#66cbe4"/></linearGradient>
          </defs>
          <rect class="c" width="326.65" height="326.65" rx="23.67" ry="23.67"/>
          <path class="d" d="m430.48,200.85v66.88h-27.04V58.74h59.3c16.8,2.27,31,9.19,42.58,20.77,13.85,13.93,20.77,30.65,20.77,50.16s-6.92,36.46-20.77,50.35c-13.85,13.89-30.56,20.83-50.16,20.83h-24.69Zm0-114.94v87.77h24.69c12.02,0,22.31-4.29,30.89-12.87,8.58-8.58,12.87-18.96,12.87-31.15s-4.29-22.42-12.87-30.96c-8.58-8.53-18.87-12.8-30.89-12.8h-24.69Z"/><path class="d" d="m607.46,122.35c20.55,0,38.07,7.27,52.57,21.81,14.5,14.54,21.75,32.09,21.75,52.64s-7.25,38.1-21.75,52.64c-14.5,14.54-32.02,21.81-52.57,21.81s-37.97-7.27-52.51-21.81c-14.54-14.54-21.81-32.09-21.81-52.64s7.27-38.09,21.81-52.64c14.54-14.54,32.04-21.81,52.51-21.81Zm-32.98,41.54c-9.1,9.14-13.65,20.12-13.65,32.92s4.55,23.86,13.65,32.92c9.1,9.06,20.09,13.58,32.98,13.58s23.88-4.53,32.98-13.58c9.1-9.06,13.65-20.03,13.65-32.92s-4.55-23.77-13.65-32.92c-9.1-9.14-20.09-13.71-32.98-13.71s-23.88,4.57-32.98,13.71Z"/><path class="d" d="m740.17,251.01v61.91h-27.04v-179.6h27.04v12.15c13.06-15.33,28.26-22.99,45.59-22.99,20.55,0,38.07,7.25,52.57,21.75,14.5,14.5,21.75,32.02,21.75,52.57s-7.25,38.1-21.75,52.64c-14.5,14.54-32.02,21.81-52.57,21.81-17.33,0-32.52-6.75-45.59-20.25Zm0-64.65v21.03c1.65,8.27,5.88,15.76,12.67,22.47,9.06,9.06,20.03,13.58,32.92,13.58s23.88-4.55,32.98-13.65c9.1-9.1,13.65-20.09,13.65-32.98s-4.55-23.77-13.65-32.92c-9.1-9.14-20.09-13.71-32.98-13.71s-23.86,4.57-32.92,13.71c-6.79,6.71-11.02,14.19-12.67,22.47Z"/><path class="d" d="m909.05,133.32v-50.42h27.04v50.42h24.16v27.04h-24.16v107.37h-27.04v-107.37h-24.16v-27.04h24.16Z"/><path class="d" d="m1020.86,83.56c3.74,3.66,5.62,8.14,5.62,13.45s-1.87,9.8-5.62,13.45c-3.74,3.66-8.23,5.49-13.45,5.49s-9.58-1.83-13.32-5.49c-3.74-3.66-5.62-8.14-5.62-13.45s1.87-9.8,5.62-13.45c3.74-3.66,8.18-5.49,13.32-5.49s9.71,1.83,13.45,5.49Zm.13,49.76v134.4h-27.04v-134.4h27.04Z"/>
          <path class="d" d="m1128.88,122.35c20.55,0,38.07,7.27,52.57,21.81,14.5,14.54,21.75,32.09,21.75,52.64s-7.25,38.1-21.75,52.64c-14.5,14.54-32.02,21.81-52.57,21.81s-37.97-7.27-52.51-21.81c-14.54-14.54-21.81-32.09-21.81-52.64s7.27-38.09,21.81-52.64c14.54-14.54,32.04-21.81,52.51-21.81Zm-32.98,41.54c-9.1,9.14-13.65,20.12-13.65,32.92s4.55,23.86,13.65,32.92c9.1,9.06,20.09,13.58,32.98,13.58s23.88-4.53,32.98-13.58c9.1-9.06,13.65-20.03,13.65-32.92s-4.55-23.77-13.65-32.92c-9.1-9.14-20.09-13.71-32.98-13.71s-23.88,4.57-32.98,13.71Z"/><path class="d" d="m1261.72,169.37v98.35h-27.17v-134.4h27.17v15.67c5.66-15.32,17.02-22.99,34.09-22.99,14.54,0,26.1,5.16,34.68,15.48,8.58,10.32,13,23.27,13.26,38.86v87.38h-27.04v-86.34c0-7.75-2.83-14.39-8.49-19.92-5.66-5.53-12.41-8.29-20.25-8.29s-14.46,2.7-20.12,8.1c-3.13,3.22-5.18,5.92-6.14,8.1Z"/><path class="d" d="m1410.49,238.86c3.22,3.13,7.05,4.7,11.49,4.7s8.25-1.57,11.43-4.7c3.18-3.13,4.77-7.01,4.77-11.62s-1.57-8.23-4.7-11.36c-2.18-2.18-6.84-5.53-13.98-10.06-9.75-5.31-16.68-10.01-20.77-14.11-7.14-7.14-10.71-15.72-10.71-25.73s3.57-18.74,10.71-25.93c7.14-7.18,15.76-10.78,25.86-10.78s18.5,3.4,25.21,10.19c4.79,4.79,7.84,10.15,9.14,16.07l-26.25,7.58c-.61-2.18-1.22-3.61-1.83-4.31-1.57-1.57-3.66-2.35-6.27-2.35s-4.86.94-6.73,2.81c-1.87,1.87-2.81,4.11-2.81,6.73s.91,4.83,2.74,6.66c1.65,1.57,5.4,4.22,11.23,7.97,10.8,5.92,18.63,11.32,23.51,16.2,8.45,8.53,12.67,18.68,12.67,30.43s-4.2,22.27-12.6,30.76c-8.4,8.49-18.61,12.73-30.63,12.73s-22.12-4.27-30.56-12.8c-5.84-5.83-9.67-13.1-11.49-21.81l26.91-5.75c.78,4.01,2,6.84,3.66,8.49Z"/>
          <path class="e" d="m183.96,57.16h-96.44c-5.86,0-10.6,4.75-10.6,10.6v85.32l38.81-38.81c.25-.25.52-.47.81-.66.19-.12.39-.21.58-.31.11-.05.21-.12.33-.17.22-.09.45-.15.68-.21.1-.03.2-.07.31-.09.31-.06.62-.09.94-.09.03,0,.05,0,.08,0s.05,0,.08,0c.31,0,.63.03.94.09.11.02.2.06.31.09.23.06.45.12.67.2.11.05.22.12.33.17.2.1.39.19.58.31.29.19.56.41.81.66l13.92,13.92s0,0,0,0l10.83,10.83,30.89-30.89-4.84-4.84c-1.53-1.53-.72-4.16,1.41-4.56l38.29-7.24c1.87-.35,3.51,1.29,3.16,3.16l-7.24,38.29c-.4,2.13-3.03,2.94-4.56,1.41l-4.84-4.84-48.53,48.53c-.99.99-2.32,1.54-3.72,1.54s-2.73-.55-3.72-1.54l-13.92-13.92s0,0,0,0l-10.83-10.83-36.78,36.78c-3.68,3.68-5.74,8.66-5.74,13.86v53.46c0,5.86,4.75,10.6,10.6,10.6h38.37c5.86,0,10.6-4.75,10.6-10.6v-52.78c0-4.74,3.84-8.58,8.58-8.58h38.88c34.02,0,61.6-27.58,61.6-61.6v-15.65c0-34.02-27.58-61.6-61.6-61.6Z"/>
        </svg>
      </a>
      <nav class="home__nav">
        <a class="home__nav-link" href="https://poptions.io/">Home</a>
        <a class="home__nav-link" href="https://poptions.io/about-us/">About Us</a>
        <a class="home__nav-link" href="https://poptions.io/contact-us/">Contact Us</a>
        <a class="home__nav-link" href="https://poptions.io/faq/">Support</a>
      </nav>

      <div class="home__header-right"></div>
    </header>

    <main class="home__main">
      <form class="home__form">
        <div v-if="show_verification">
          <h1 class="home__title">Success</h1>
          <div class="home__text">
            We sent an email to
            <br />{{ email }}<br />containing a verification code
          </div>
          <input class="inputField home__input"  type="text" name="verification_code" placeholder="Verification Code" v-model="verification_code" required   />
          <input class="button button--white" type="submit" :value="is_loading ? 'Verifying Code. Please Wait..' : 'Verify'" :disabled="is_loading" @click="verify__clicked" style="margin:0 auto;" />
          <a class="login__back-link" @click="back__clicked" style="display:inline-block; margin-top:20px; color:#3ad4fc; border-bottom:solid 1px #3ad4fc;">Back</a>
        </div>


        <div v-else>
          <h1 class="home__title">Join Like-Minded Traders</h1>
          <div class="home__text">Enter your email and we will send you a login code</div>
          <input class="inputField home__input"  type="email" name="email" id="email" placeholder="Email Address" v-model="email" required   />
          <input class="button button--white" type="submit" :value="is_loading ? 'Logging In. Please Wait..' : 'Send Login Code'" :disabled="is_loading" @click="login__clicked" style="margin:0 auto;" />
        </div>
      </form>
    </main>

    <footer>

    </footer>

    <!-- <div class="login__error-message" v-if="error_message.length > 0">{{ error_message}}</div> -->

   <!-- <form @submit.prevent="loginForm__submitted">
        <div v-if="show_success">
          <h1>Success</h1>
          <div>We sent and email to {{ email }}.</div>
          <div>Click the provided link to login.</div>
          <a @click="back__clicked" style="display:inline-block; margin-top:20px; color:#3ad4fc; border-bottom:solid 1px #3ad4fc;">Back</a>
        </div>

        <div v-else>
          <h1>Sign In</h1>
          <div>Sign in via magic link with your email below</div>
          <input class="inputField"  type="email" placeholder="Your email" v-model="email" required style="border:none; border-radius:3px; width:390px; margin:20px auto 10px; padding:8px 12px;  " />
          <input class="button button--white" type="submit" :value="is_loading ? 'Logging In. Please Wait..' : 'Send magic link'" :disabled="is_loading" style="margin:0 auto;" />
        </div>
    </form> -->

  </div>
</template>

<script>
import { SupabaseClient } from '../models/SuperbaseClient'

export default {
  data(){
    return{
      email: "",
      verification_code: "",
      is_loading: false,
      show_verification: false,
      show_success: false
    }
  },
  mounted(){
    console.log("p", this.destination );

    if(this.is_logged_in){
      this.$router.push("/console/scanner");
    }
  },
  computed:{
    destination(){
      return this.$route.query.destination;
    },
    auth_session(){
      return this.$store.getters.auth_session;
    },
    is_logged_in(){
      return this.$store.getters.auth_session && this.$store.getters.auth_session.user;
    }
  },
  methods: {
    async verify__clicked(event){
      if(this.verification_code.trim().length < 2) return;
      try {
        this.is_loading = true;
        const { data, error } = await SupabaseClient.auth.verifyOtp({ email:this.email, token: this.verification_code, type: 'email' });

        console.log("data", data);
        console.log("error", error);

        if (error) throw error;

        this.$router.push('/console/scanner');

      } catch (error) {
          alert(error.message)
      } finally {
        this.is_loading = false;
      }

    },
    async login__clicked(event){

      if(this.email.trim().length < 3) return;

      try {
        this.is_loading = true;
        const { error } = await SupabaseClient.auth.signInWithOtp({ email: this.email.trim(), options: { emailRedirectTo: window.location.origin }});


        if (error) throw error

        this.show_verification = true;

      } catch (error) {

        console.log("error message", error.message)
        console.log("error stack", error.stack)
        console.log("error", error)

          alert(error.message)
      } finally {
        this.is_loading = false;
      }
    },
    back__clicked(event){
      this.show_verification = false;
      this.verification_code = "";
      this.show_success = false;
    }
  }
}
/*import { SupabaseClient } from '../models/SuperbaseClient'

export default {
  data(){
    return{
      email: "",
      is_loading: false,
      show_success: false
    }
  },
  mounted(){
      console.log("p", this.destination );
  },
  computed:{
    destination(){
      return this.$route.query.destination;
    },
  },
  methods: {
    async loginForm__submitted(event){

      if(this.email.trim().length < 3) return;

      try {
        this.is_loading = true;
        const { error } = await SupabaseClient.auth.signInWithOtp({ email: this.email.trim(), options: { emailRedirectTo: window.location.origin }});
        if (error) throw error
        this.show_success = true;

      } catch (error) {
        if (error instanceof Error) {
          alert(error.message)
        }
      } finally {
        this.is_loading = false;
      }
    },
    back__clicked(event){
      this.show_success = false;
    }
  }
}*/
</script>
